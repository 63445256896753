<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import { usePageStore } from '@voix/store/pageStore'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import type {
  VoixTextFieldInterface,
} from '@voix/types'
import Sandpiper from '~/layouts/Sandpiper.vue'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
  }
}>()

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const { data: currentResort } = await $voixNuxtApi(
    `/api/sites/${currentPage.value?.site_id}/resorts${currentPage.value?.path}`,
)

const { data: specials } = await $voixNuxtApi(
    `/api/specials?resort_id=${currentResort.value.data.id}`,
)

defineSlice({
  name: {
    label: 'SandPiper Special Offer Slider',
    group: 'sandpiper',
    layouts: ['Sandpiper'],
  },
  preview: 'SlicesSandpiperSandpiperSpecialOfferSlider.jpg',
  fields: {
    title: { label: 'Title', type: 'text', name: 'title' },
  },
  slots: [],
})
</script>

<template>
  <div class="py-12 flex flex-col items-center">
    <div class="pb-8 text-3xl text-center font-sandpiper-display font-medium font-midnight">
      {{ fields.title.value }}
    </div>

    <ClientOnly>
      <Swiper
        v-if="specials?.data.length > 0" slides-per-view="auto"
        :modules="[Pagination, Navigation]"
        :space-between="30"
        :centered-slides="false"
        :pagination="{ clickable: true }"
        :navigation="true"
        :free-mode="{ enabled: false }"
        style="margin:0"
        class=" max-w-[100vw]"
      >
        <SwiperSlide
          v-for="(special, key) in specials.data"
          :key="key"
          class="md:mx-24  max-w-[1000px]"
        >
          <SandpiperSpecialSlide
            :index="key"
            :special="special"
          />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<style scoped>
</style>

<style>
:root {
  --swiper-theme-color: #CA9117;
  --swiper-pagination-bottom: 10px;
}
</style>
